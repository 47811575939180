<template>
  <section class="main-content">
    <!-- No Profile Exists Message -->
    <div v-if="!marketplaceProfile"
      class="main-content-card">
      <div class="large-message">
        <i class="fas fa-screwdriver-wrench"
          aria-hidden="true" />
        <h4>{{ $t('marketplace.no-profile') }}</h4>
      </div>
    </div>

    <div v-else
      v-loading="marketplaceData.length <= 1">
      <!-- Collapsible message if it's your own profile -->
      <el-collapse v-if="ownProfile"
        v-model="collapsibles">
        <el-collapse-item name="info">
          <template slot="title">
            <i class="far fa-circle-info mr-1"
              aria-hidden="true" />
            {{ $t('marketplace.your-profile') }}
          </template>

          <p>{{ $t('marketplace.marketplace-intro') }}</p>
          <p>{{ $t('marketplace.point-click') }}</p>
        </el-collapse-item>
      </el-collapse>

      <!-- Main Content Card -->
      <div class="main-content-card">
        <div class="profile-header">
          <!-- Back to Marketplace Button -->
          <el-button @click="$router.push('/marketplace', () => {})">
            <i class="fas fa-arrow-left mr-0-5"
              aria-hidden="true" />
            {{ $t('marketplace.back-to-marketplace') }}
          </el-button>

          <!-- Marketplace Logo -->
          <span>
            <mu-mega-icon icon="MarketplaceLabel"
              height="50" />
          </span>
        </div>

        <div v-if="marketplaceProfile"
          class="marketplace-profile-content">
          <!-- Left column -->
          <div class="width-200px mr-2">
            <!-- Component for sending message to the seller. -->
            <contact-seller :visible.sync="showContactSellerModal"
              :product-uid="null"
              :marketplace-profile="marketplaceProfile" />

            <!-- Company Logo -->
            <img v-if="marketplaceProfile.image"
              :src="marketplaceProfile._logo"
              :alt="$t('images.marketplace-profile-logo')"
              class="marketplace-profile-logo"
              @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">

            <div class="left-sections">
              <!-- Contact Button -->
              <el-button type="primary"
                class="full-width"
                @click="showContactSellerModal = true">
                <i class="far fa-envelope"
                  aria-hidden="true" />
                {{ $t('marketplace.contact') }}
              </el-button>

              <!-- Website -->
              <div v-if="marketplaceProfile.www">
                <h4>{{ $t('general.website') }}</h4>
                <a :href="marketplaceProfile.www"
                  target="_blank"
                  rel="nofollow"
                  class="word-wrap-break-word">
                  {{ marketplaceProfile.www }}
                </a>
                <external-link-indicator class="ml-0-5" />
              </div>

              <!-- Phone -->
              <div v-if="marketplaceProfile.phone">
                <h4>{{ $t('general.phone') }}</h4>
                <a :href="`tel:${marketplaceProfile.phone}`">
                  {{ marketplaceProfile.phone }}
                </a>
              </div>

              <!-- Headquarters -->
              <div v-if="hasHeadquarters">
                <h4>{{ $t('marketplace.headquarters') }}</h4>
                <p>{{ marketplaceProfile.street1 }}</p>
                <p>{{ marketplaceProfile.street2 }}</p>
                <p>{{ marketplaceProfile.city }}</p>
                <p>{{ marketplaceProfile.state }}</p>
                <p>{{ marketplaceProfile.country }}</p>
                <p>{{ marketplaceProfile.postcode }}</p>
              </div>

              <!-- Social Media -->
              <div v-if="hasSocialMedia">
                <h4>{{ $t('marketplace.social') }}</h4>

                <div class="social-media">
                  <a v-if="marketplaceProfile.facebook"
                    :href="marketplaceProfile.facebook"
                    :aria-label="$t('marketplace.facebook')"
                    target="_blank"
                    rel="nofollow">
                    <i class="fab fa-facebook color-facebook"
                      aria-hidden="true" />
                  </a>
                  <a v-if="marketplaceProfile.twitter"
                    :href="marketplaceProfile.twitter"
                    :aria-label="$t('marketplace.x')"
                    target="_blank"
                    rel="nofollow">
                    <i class="fab fa-x-twitter color-twitter"
                      aria-hidden="true" />
                  </a>
                  <a v-if="marketplaceProfile.linkedin"
                    :href="marketplaceProfile.linkedin"
                    :aria-label="$t('marketplace.linkedin')"
                    target="_blank"
                    rel="nofollow">
                    <i class="fab fa-linkedin-in color-linkedin"
                      aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Right column -->
          <div class="right-column">
            <!-- Company Name -->
            <h2 class="title-text">
              {{ marketplaceProfile.companyName }}
            </h2>

            <!-- List of Provider Types -->
            <el-tag v-for="(provider, index) in marketplaceProfile.providerTypes"
              :key="provider.id"
              :class="{ 'mr-1': index !== marketplaceProfile.providerTypes.length - 1 }"
              class="mb-1">
              {{ provider.description }}
            </el-tag>

            <!-- Company Bio -->
            <p class="white-space-pre-line word-break-normal mt-0">
              {{ marketplaceProfile.companyBio }}
            </p>

            <!-- Services -->
            <h3 class="title-text">
              {{ $t('general.services') }}
            </h3>

            <service-profile v-for="service in visibleServices"
              :key="service.productUid"
              :marketplace-service="service"
              :marketplace-profile="marketplaceProfile"
              :own-profile="ownProfile" />
          </div>

          <!-- Footer Buttons (Recently Viewed and Similar Providers) -->
          <provider-dropdowns :marketplace-profile="marketplaceProfile" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// External tools
import { mapGetters, mapState } from 'vuex'
// Internal tools
import { slug } from '@/helpers.js'
import { setFallbackImage } from '@/utils/fallbackImage'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
// Components
import ServiceProfileComponent from '@/components/marketplace/ServiceProfile.vue'
import ProviderDropdowns from '@/components/marketplace/ProviderDropdowns.vue'
import ContactSellerComponent from '@/components/marketplace/MarketplaceContactSeller.vue'
import ExternalLinkIndicator from '@/components/ui-components/ExternalLinkIndicator.vue'

export default {
  name: 'MarketplaceProfile',

  components: {
    'service-profile': ServiceProfileComponent,
    'provider-dropdowns': ProviderDropdowns,
    'contact-seller': ContactSellerComponent,
    'external-link-indicator': ExternalLinkIndicator,
  },

  // Before entering the route, check if the user is a managed account.
  // If they are, redirect them to the services page (they're not allowed to go on the marketplace!).
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (vm.isManagedAccount) {
        next(resolveServicesPage())
      }
    })
  },

  data() {
    return {
      showContactSellerModal: false,
      collapsibles: ['info'],
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('Auth', ['isManagedAccount']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Services', ['findPort']),
    /**
     * Returns the marketplace profile for the company in the URL.
     */
    marketplaceProfile() {
      const companyName = this.$route.params.companyPath
      return this.marketplaceData.find(profile => companyName === slug(profile.companyName))
    },
    /**
     * Returns true if the user is on their own profile.
     */
    ownProfile() {
      return this.marketplaceProfile && this.companyUid === this.marketplaceProfile.companyUid
    },
    /**
     * Returns true if the profile has filled in any of the social media fields.
     */
    hasSocialMedia() {
      return this.marketplaceProfile.facebook || this.marketplaceProfile.twitter || this.marketplaceProfile.linkedin
    },
    /**
     * Returns true if the profile has filled in any of the headquarter fields.
     */
    hasHeadquarters() {
      return this.marketplaceProfile.street1
        || this.marketplaceProfile.street2
        || this.marketplaceProfile.city
        || this.marketplaceProfile.state
        || this.marketplaceProfile.country
        || this.marketplaceProfile.postcode
    },
    /**
     * Returns the services for the profile, with additional information about each service.
     */
    visibleServices() {
      const servicesExtended = this.marketplaceProfile.services.map(service => {
        const serviceInfo = this.findPort(service.productUid) || {}
        return {
          ...service,
          serviceInfo,
        }
      })

      // If we're viewing our own profile, we need to filter out any private services
      return servicesExtended
        .filter(service => (this.ownProfile ? service.marketplaceVisible : service))
        .sort((a, b) => a.title.localeCompare(b.title))
    },
  },

  methods: {
    setFallbackImage,
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
}

.main-content-card {
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.marketplace-profile-content {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color-base);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

.large-message {
  display: flex;
  flex-direction: column;
  text-align: center;

  .fas {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
}

::v-deep {
  .el-collapse {
    border: none;

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .el-collapse-item {
      border: 1px solid var(--border-color-base);
      border-radius: var(--border-radius-base);
      margin-bottom: 1.5rem;

      .el-collapse-item__header {
        font-size: 1.6rem;
        padding-left: 1.5rem;
        border-radius: var(--border-radius-base);

        &.is-active {
          border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
        }
      }

      .el-collapse-item__wrap {
        border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);

        .el-collapse-item__content {
          padding: 1.5rem;
        }
      }
    }
  }
}

.title-text {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1rem;
}
h2.title-text {
  font-size: 3.5rem;
}
h3.title-text {
  font-size: 2.5rem;
}

.left-sections > div {
  margin: 2rem 0;

  p {
    margin: 0;
  }
}

.social-media {
  margin-top: 1rem;

  a {
    font-size: 2.5rem;
    margin-right: 1rem;
    padding: 0.75rem;
    border-radius: 1rem;

    &:hover {
      background-color: var(--color-info-light-5);
    }
  }
}

.marketplace-profile-logo {
  width: 200px;
  height: 200px;
}

.right-column {
  width: calc(100% - 220px);
}

.width-200px {
  width: 200px;
}

.word-wrap-break-word {
  word-wrap: break-word;
}
</style>
