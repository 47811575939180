<template>
  <el-dialog :visible="visible"
    :before-close="handleClose"
    :title="$t('connections.new-vxc')">
    <el-form ref="sourceSelectForm"
      :model="sourceSelectForm"
      :rules="sourceSelectRules">
      <!-- Display the selected destination port -->
      <h4>{{ $t('marketplace.target') }}</h4>
      <div v-if="targetPort"
        class="port-summary">
        <mu-mega-icon icon="vxc"
          class="icon" />
        <div class="flex-column ml-1">
          <div>{{ theMarketplaceName }}</div>
          <div class="font-weight-300">
            {{ targetPortLocationNameAndCountry }}
          </div>
        </div>
      </div>

      <!-- Display the available source ports -->
      <h4 class="mt-2">
        {{ $t('marketplace.connection-from') }}
      </h4>

      <el-form-item :label="$t('marketplace.select-source')"
        prop="sourceSelect">
        <div class="port-select-holder">
          <el-table ref="portSelectTable"
            :data="availableSources"
            highlight-current-row
            show-header
            max-height="450"
            :empty-text="$t('connections.no-matching-source-port')"
            row-class-name="port-row"
            class="port-select-table full-width collapsed-border hide-table-header"
            @row-click="handlePortSelect">
            <el-table-column property="port"
              label="Port"
              min-width="100">
              <template #default="{row}">
                <div class="port-cell">
                  <div>
                    <mu-mega-icon icon="vxc"
                      class="icon" />
                  </div>
                  <div class="ml-1">
                    <div class="port-header">
                      {{ row.productName }}
                    </div>
                    <div v-if="row._location && row._location.formatted">
                      {{ row._location.formatted.short }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="setVisible(false)">
        {{ $t('general.cancel') }}
      </el-button>
      <el-button type="primary"
        data-testid="next-button"
        @click="addConnection">
        {{ $t('general.next') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// This is set up for use of visible.sync, so when the dialog needs to close it emits the update message.
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    productUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      company: this.$store.state.Company.data,
      sourceSelectForm: {
        sourceSelect: null,
      },
      sourceSelectRules: {
        sourceSelect: { required: true, message: this.$t('connections.no-selected-source-port'), trigger: 'none' },
      },
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapGetters('Services', ['findPort', 'canPortBeConnectedToByVxc']),
    targetPort() {
      return this.findPort(this.productUid)
    },
    availableSources() {
      return this.services
        .filter(service => this.canPortBeConnectedToByVxc(service, this.targetPort))
        .sort((a, b) => a.productName.localeCompare(b.productName))
    },
    theMarketplaceName() {
      return this.targetPort.productName || this.targetPort._marketplaceTitle || this.targetPort.title
    },
    targetPortLocationNameAndCountry() {
      const locationInfo = this.targetPort?.locationInfo
      if (!locationInfo) return ''
      const locationName = locationInfo.name
      const locationCountry = locationInfo.country
      const formattedString = `${locationName}, ${locationCountry}`
      return formattedString
    },
  },

  methods: {
    setVisible(newValue) {
      this.$emit('update:visible', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    handlePortSelect(val) {
      if (!val || (this.sourceSelectForm.sourceSelect && this.sourceSelectForm.sourceSelect === val.productUid)) {
        this.$refs.portSelectTable.setCurrentRow()
        this.sourceSelectForm.sourceSelect = null
      } else {
        this.sourceSelectForm.sourceSelect = val.productUid
      }
    },
    addConnection() {
      this.$refs.sourceSelectForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('connections.no-selected-source-port'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
        } else {
          const payload = {
            targetUid: this.productUid,
            sourceUid: this.sourceSelectForm.sourceSelect,
            connectType: this.targetPort.connectType,
          }

          const path = `/create-connection/${payload.sourceUid}?bEnd=${payload.targetUid}&type=${payload.connectType}`
          this.setVisible(false)
          this.$router.push(path, () => {
            // empty function is intentional
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.port-summary {
  font-size: 1.6rem;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 350px;
  margin: auto;
  width: fit-content;
  line-height: 0;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}

svg.icon {
  display: inline-block;
  color: var(--color-text-regular);
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
}

.port-select-holder {
  background-color: #fbfbfb;
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin-top: 1rem;
  clear: both;
}
.is-error .port-select-holder {
  border-color: var(--color-danger);
}
.is-success .port-select-holder {
  border-color: var(--color-success);
}
</style>

<style lang="scss">
.port-select-table table tr.port-row {
  background-color: #fbfbfb;
  &:hover > td {
    background-color: #fbfbfb;
  }
}

.port-select-table.el-table td {
  padding: 0;
  border: 0;
}
.port-select-table .port-cell {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  padding: 0.5rem;
  margin: 0.25rem 0;
}
.port-cell {
  background-color: var(--color-white);
  word-break: normal;
  word-wrap: break-word;
  &:hover {
    border-color: var(--color-primary-light-5);
    background-color: var(--color-primary-light-9);
  }
  .port-header {
    font-weight: 700;
  }
}

.current-row .port-cell {
  .port-header {
    color: var(--color-primary);
  }
  border-color: var(--color-primary);
  background-color: var(--color-primary-light-8);
  svg.icon {
    color: var(--color-primary);
    fill: currentColor;
  }
}
.port-select-table .el-table__body tr.current-row > td {
  background-color: #fbfbfb;
}
</style>
