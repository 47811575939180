<template>
  <div class="footer-buttons">
    <!-- Needs to be wrapped in a div to prevent bug where buttons are small and empty -->
    <div class="d-contents">
      <!-- Recently Viewed Providers -->
      <el-dropdown v-if="recentProviders.length"
        trigger="click"
        placement="top"
        @command="handleProviderClick">
        <el-button class="mr-0-5">
          {{ $t('marketplace.recently-viewed') }}
          <i class="el-icon-arrow-down"
            aria-hidden="true" />
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="recent in recentProviders"
              :key="recent.companyUid"
              :command="recent.link">
              <el-tooltip placement="right"
                :content="recent.companyBio || $t('target-select.no-bio')"
                :open-delay="500">
                <div class="flex-row-centered">
                  <img :src="recent._logo"
                    :alt="`${recent.companyName} ${$t('images.company-logo')}`"
                    class="other-logo-image"
                    @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
                  <h4>{{ recent.companyName }}</h4>
                </div>
              </el-tooltip>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- Similar Service Providers -->
      <el-dropdown trigger="click"
        placement="top"
        @command="handleProviderClick">
        <el-button class="mr-0-5">
          {{ $t('marketplace.similar-providers') }}
          <i class="el-icon-arrow-down"
            aria-hidden="true" />
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="provider in rankedMarketplace"
              :key="provider.companyUid"
              :command="provider.link">
              <el-tooltip placement="right"
                :content="provider.companyBio || $t('target-select.no-bio')"
                :open-delay="500">
                <div class="flex-row-centered">
                  <img :src="provider._logo"
                    :alt="`${provider.companyName} ${$t('images.provider-logo')}`"
                    class="other-logo-image"
                    @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
                  <h4>{{ provider.companyName }}</h4>
                </div>
              </el-tooltip>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { slug } from '@/helpers.js'
import { setFallbackImage } from '@/utils/fallbackImage'

export default {
  name: 'ProviderDropdowns',

  props: {
    marketplaceProfile: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      recentItems: [],
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('Auth', ['personUid']),
    ...mapGetters('Marketplace', ['marketplaceUserProfile']),
    ...mapGetters('Services', ['findPort']),
    /**
     * Returns the recent profiles filtering out the user's profile.
     */
    recentProviders() {
      if (this.recentItems.length && this.marketplaceUserProfile) {
        return this.recentItems
          .filter(profile => profile.companyUid !== this.marketplaceUserProfile.companyUid)
          .reverse()
      }
      return []
    },
    /**
     * Returns the ranked marketplace profiles for display in the similar providers dropdown.
     */
    rankedMarketplace() {
      if (!this.marketplaceData || !this.marketplaceProfile) return []

      const currentLocations = this.getUniqueLocations(this.marketplaceProfile.services)
      return this.marketplaceData
        .map(profile => {
          let rank = 0
          // 3 points for each provider type matching
          profile.providerTypes.forEach(provider => {
            if (this.marketplaceProfile.providerTypes.indexOf(provider) >= 0) {
              rank = rank + 3
            }
          })
          // 1 point for each matching region
          const profileLocations = this.getUniqueLocations(profile.services)
          currentLocations.forEach(loc => {
            if (profileLocations.indexOf(loc) >= 0) {
              rank++
            }
          })

          return Object.assign({}, profile, {
            rank: rank,
            link: `/marketplace/${slug(profile.companyName)}`,
          })
        })
        .sort((a, b) => {
          if (a.rank > b.rank) return 1
          if (a.rank < b.rank) return -1
          return 0
        })
        .slice(0, 5)
    },
  },

  mounted() {
    // Fetch the list of recent profiles from local storage
    this.fetchRecentProfiles()
    // Save the profile we've just visited to the recently viewed list
    this.saveRecentProfile()
  },

  methods: {
    setFallbackImage,
    handleProviderClick(profileUrl) {
      this.$router.push(profileUrl, () => { /* empty function is intentional */ })
    },
    getUniqueLocations(services) {
      if (!services || !services.length) return []

      const locations = []
      services.forEach(({ productUid }) => {
        const serviceInfo = this.findPort(productUid) || null
        if (serviceInfo) {
          locations.push(serviceInfo.locationId)
        }
      })
      return locations.unique()
    },
    /**
     * Fetches the recent profiles from local storage and sets them to the recentItems array.
     */
    fetchRecentProfiles() {
      const items = []
      const recentCompanyUidsLs = JSON.parse(localStorage.getItem(`_mpRecentProviders_${this.personUid}`))

      if (recentCompanyUidsLs) {
        recentCompanyUidsLs.forEach(companyUid => {
          const recentProfileData = this.marketplaceData.find(profile => profile.companyUid === companyUid)

          if (recentProfileData) {
            items.push({
              ...recentProfileData,
              link: `/marketplace/${slug(recentProfileData.companyName)}`,
            })
          }
        })
      }

      this.recentItems = items
    },
    /**
     * Saves the current profile to the recent profiles list in local storage.
     */
    saveRecentProfile() {
      // Don't save if no profile
      if (!this.marketplaceProfile) return

      // Get the current list of recent profiles as company UIDs
      let recentCompanyUids = JSON.parse(localStorage.getItem(`_mpRecentProviders_${this.personUid}`))

      // If no list, initialise an empty array
      if (!recentCompanyUids) recentCompanyUids = []
      // If the profile we're viewing is already in the list, stop here
      if (recentCompanyUids.includes(this.marketplaceProfile.companyUid)) return

      // If the list is at the maximum length, remove the oldest item
      if (recentCompanyUids.length >= 3) {
        recentCompanyUids.shift()
      }

      recentCompanyUids.push(this.marketplaceProfile.companyUid)
      localStorage.setItem(`_mpRecentProviders_${this.personUid}`, JSON.stringify(recentCompanyUids))
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.el-dropdown {
  width: 100%;
}

.el-button {
  width: 100%;
}

.other-logo-image {
  margin-right: 1rem;
  width: 80px;
  height: 80px;
  background-color: white;
}
</style>
