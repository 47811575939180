<template>
  <div class="service-profile">
    <!-- Component for sending message to the seller. -->
    <contact-seller :visible.sync="showContactSeller"
      :product-uid="service.productUid"
      :marketplace-profile="marketplaceProfile" />

    <!-- Component for adding a new connection when we need to select A end -->
    <new-connection :visible.sync="showNewConnection"
      :product-uid="service.productUid"
      :marketplace-profile="marketplaceProfile" />

    <div class="service-summary"
      @click.prevent="expanded = !expanded">
      <mu-mega-icon icon="vxc"
        class="service-icon service-profile-icon" />

      <div class="ml-6 flex-0">
        <div v-if="service.serviceInfo">
          <!-- Marketplace Service Title (Not the same as the title assigned to the service) -->
          <h4>{{ theMarketplaceName }}</h4>

          <!-- Location Info -->
          <div v-if="service.serviceInfo.locationInfo && service.serviceInfo.locationInfo.formatted">
            <template v-if="service.serviceInfo.buyoutPort">
              [{{ $t('ports.open-port') }}]
            </template>
            {{ service.serviceInfo.locationInfo.formatted.long }}
          </div>

          <div v-if="['AWS', 'AWSHC'].includes(service.serviceInfo.connectType)">
            {{ $t(service.serviceInfo.connectType === 'AWSHC' ? 'aws.aws-hosted-connection' : 'aws.aws-vif') }}
            <el-tooltip v-if="service.serviceInfo.diversityZone"
              placement="top"
              :content="$t('connections.aws-diversity-tooltip')"
              :open-delay="500">
              <i class="far fa-dot-circle"
                :class="`diversity-color-${service.serviceInfo.diversityZone}`"
                aria-hidden="true" />
            </el-tooltip>
          </div>
          <div v-else-if="service.serviceInfo.diversityZone"
            :title="$t(`services.${service.serviceInfo.diversityZone}-zone`)">
            {{ $t('services.diversity-zone') }}
            <i class="far fa-dot-circle"
              :class="`diversity-color-${service.serviceInfo.diversityZone}`"
              aria-hidden="true" />
          </div>
        </div>
        <div v-if="service.serviceTypes.length > 0">
          <el-tag v-for="(tag, idx) in service.serviceTypes"
            :key="idx"
            type="info"
            size="small"
            class="mr-0-5 my-0-3">
            {{ tag.description }}
          </el-tag>
        </div>
      </div>

      <div class="mr-1 ml-auto">
        <template v-if="!ownProfile">
          <!-- Connect Button -->
          <el-tooltip v-if="connect.active"
            placement="top"
            :content="$t('marketplace.create-vxc')"
            :open-delay="500">
            <el-button type="primary"
              name="addMarketplaceConnection"
              class="create-vxc-button"
              :data-serviceid="service.serviceInfo.productUid"
              @click="newConnection(connect.link)">
              <i class="fal fa-plus-circle"
                aria-hidden="true" />
              {{ $t('marketplace.connect') }}
            </el-button>
          </el-tooltip>

          <!-- No Service Button -->
          <el-tooltip v-else
            placement="top"
            :content="$t('marketplace.no-deployed-services')"
            :open-delay="500">
            <el-button type="primary"
              class="no-deployed-ports-button"
              @click="showNoAvailableDestinationsDialog">
              <i class="fal fa-plus-circle"
                aria-hidden="true" />
              {{ $t('general.service') }}
            </el-button>
          </el-tooltip>
        </template>

        <!-- Expand Arrow -->
        <el-tooltip placement="top"
          :content="expanded ? $t('general.hide-details') : $t('general.show-details')"
          :open-delay="500">
          <el-button class="toggle-details-button"
            @click.stop="expanded = !expanded">
            <i class="fal fa-chevron-up expand-arrow"
              :class="{ 'active': !expanded }"
              aria-hidden="true" />
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <!-- Stuff to show if user wants to see details -->
    <el-collapse-transition>
      <div v-if="expanded"
        class="service-details">
        <div class="expanded-details">
          <!-- Status -->
          <p v-if="upTimeInfo">
            <span class="font-weight-400">{{ $t('general.status') }}:</span> {{ upTimeInfo }}
          </p>

          <!-- Contact Button -->
          <el-button class="my-1 px-1 py-0 height-32px"
            @click="showContactSeller = true">
            <i class="fal fa-envelope"
              aria-hidden="true" />
            {{ $t('marketplace.contact') }}
          </el-button>
        </div>

        <!-- Service Description -->
        <p v-if="service.description"
          class="line-height-1-2em white-space-pre-line word-break-normal">
          {{ service.description }}
        </p>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import humanizeDuration from 'humanize-duration'

import { getLocaleLanguage } from '@/helpers'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

import ContactSellerComponent from '@/components/marketplace/MarketplaceContactSeller.vue'
import NewConnectionComponent from '@/components/marketplace/MarketplaceNewConnection.vue'

export default {
  name: 'ServiceProfile',

  components: {
    'contact-seller': ContactSellerComponent,
    'new-connection': NewConnectionComponent,
  },

  props: {
    marketplaceService: {
      type: Object,
      required: true,
    },
    marketplaceProfile: {
      type: Object,
      required: true,
    },
    ownProfile: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      expanded: false,
      company: this.$store.state.Company.data,
      service: {
        contactEmail: null,
        description: null,
        metadata: null,
        serviceInfo: {},
        serviceTypes: [],
        title: null,
        productUid: '',
      },
      maxWordLength: 200,
      serviceTypeIds: [],
      showContactSeller: false,
      showNewConnection: false,
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapGetters('Services', ['canPortBeConnectedToByVxc']),
    /**
     * Returns the name of the marketplace service.
     */
    theMarketplaceName() {
      return this.service.title
        ? this.service.title
        : this.service.serviceInfo.productName || this.service.serviceInfo.title
    },
    connect() {
      const c = {
        active: false,
        link: '/',
      }
      if (!this.service) return c

      const connectable = this.services.filter(service => {
        return this.canPortBeConnectedToByVxc(service, this.service.serviceInfo)
      })

      if (connectable.length > 1) {
        // We will need to ask the user which of their available ports they want to connect from
        c.active = true
        c.link = null
      } else if (connectable.length === 1) {
        // Only one suitable port - automatically go to the add connection page
        c.active = true
        const payload = {
          targetUid: this.service.serviceInfo.productUid,
          sourceUid: connectable[0].productUid,
          connectType: this.service.serviceInfo.connectType,
        }

        c.link = `/create-connection/${payload.sourceUid}?bEnd=${payload.targetUid}&type=${payload.connectType}`
      }
      return c
    },
    upTimeInfo() {
      let info = ''
      if (this.service.serviceInfo.provisioningStatus) {
        info += this.service.serviceInfo.provisioningStatus
      }
      if (this.service.serviceInfo.createDate) {
        if (info !== '') {
          info += ': '
        }

        const duration = DateTime.now()
          .diff(DateTime.fromMillis(this.service.serviceInfo.createDate))
          .milliseconds

        info += this.$t('marketplace.live-time', {
          time: humanizeDuration(duration, {
            language: getLocaleLanguage(this.$i18n.locale),
            largest: 1,
            units: ['y', 'mo', 'd', 'h', 'm'],
            round: true,
          }),
        })
      }

      return info
    },
  },

  watch: {
    marketplaceService(nv) {
      Object.assign(this.service, nv)
      this.serviceTypeIds = this.service.serviceTypes.map(element => {
        return element.id
      })
    },
    // This is dumb but we need to watch both of these props to update the serviceTypes array
    serviceTypeIds() {
      this.updateServiceTypes()
    },
    serviceTypes() {
      this.updateServiceTypes()
    },
  },

  created() {
    Object.assign(this.service, this.marketplaceService)
    this.serviceTypeIds = this.service.serviceTypes.map(element => {
      return element.id
    })
    this.tags = this.service.serviceTypes.map(t => t.description)
  },

  methods: {
    /**
     * Filter the serviceTypes array based on whether they are in the serviceTypeIds array.
     */
    updateServiceTypes() {
      const filteredServiceTypes = this.service.serviceTypes.filter(serviceType => {
        return this.serviceTypeIds.includes(serviceType.id)
      })
      this.service.serviceTypes = filteredServiceTypes
    },
    /**
     * Redirects the user to the new connection page for the specified destination.
     * @param link The link to redirect to when the user clicks the connect button.
     */
    newConnection(link) {
      if (link) {
        this.$router.push(link, () => { /* empty function is intentional */ })
      } else {
        this.showNewConnection = true
      }
    },
    /**
     * Validates the description field to ensure it is not too long.
     * @param {object} _rule
     * @param {string} value
     * @param {function} callback
     */
    validateDescription(_rule, value, callback) {
      if (!value) {
        callback()
      } else if (value.length > 2000) {
        callback(this.$tc('validations.max-length', 2000, { max: 2000 }))
      } else if (value.trim().split(/[\s]+/).length > this.maxWordLength) {
        callback(this.$t('validations.description-too-long'))
      } else {
        callback()
      }
    },
    /**
     * Opens a dialog to inform the user that there are no available destinations to connect to.
     * If the user confirms, they are redirected to the services page.
     */
    showNoAvailableDestinationsDialog() {
      this.$confirm(this.$t('marketplace.no-services-message'), this.$t('marketplace.no-services-title'), {})
        .then(() => {
          this.$router.push(resolveServicesPage())
        })
        .catch(() => { /* empty function is intentional */ })
    },
    /**
     * Fills in the service title with the company name and location if it is blank and the user triggers the blur event.
     */
    fillBlankServiceTitle() {
      if (this.service.title) return

      this.service.title = this.$t('marketplace.company-at-location', {
        company: this.company.legalName,
        location: this.service.serviceInfo.locationInfo.name,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.service-profile {
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  margin: 1rem 0;
}

.service-profile-icon {
  margin-left: 1rem;
}

.service-summary {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 1rem 0;
  cursor: pointer;
}

.service-details {
  border-top: 1px solid var(--border-color-base);
  padding: 0 1rem;
}

.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}

.toggle-details-button {
  padding: 0;
  width: 32px;
  height: 32px;
}
.create-vxc-button,
.no-deployed-ports-button {
  padding: 0;
  width: 138px;
  height: 32px;
}

.height-32px {
  height: 32px;
}

.flex-0 {
  flex: 0%;
}

.my-0-3 {
  margin-block: 0.3rem;
}

.expanded-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }
}
</style>
