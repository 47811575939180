import { render, staticRenderFns } from "./MarketplaceNewConnection.vue?vue&type=template&id=ee3a87bc&scoped=true"
import script from "./MarketplaceNewConnection.vue?vue&type=script&lang=js"
export * from "./MarketplaceNewConnection.vue?vue&type=script&lang=js"
import style0 from "./MarketplaceNewConnection.vue?vue&type=style&index=0&id=ee3a87bc&prod&lang=scss&scoped=true"
import style1 from "./MarketplaceNewConnection.vue?vue&type=style&index=1&id=ee3a87bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee3a87bc",
  null
  
)

export default component.exports